<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <p v-bind="attrs" v-on="on" id="vendorDetail"></p>
    </template>

    <v-card v-if="result !== null" style="padding:20px;" class="text-center">
      <v-row>
        <v-col cols="12" style="font-size:22px; font-weight:bold;">
          Detail Vendor
          <v-icon
            style="position:absolute; top:0; right:0; cursor:pointer;"
            @click="close"
            >mdi-close</v-icon
          >
        </v-col>
        <v-col
          cols="12"
          style="border:1px solid #e0e0e0; text-align:left; margin-bottom:10px; display:flex;"
        >
          <div style="width:50%;">
            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Nama
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.name"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                No. Handphone 1
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.phone1"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                No. Handphone 2
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.phone2"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Badan Usaha
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="
                  result.business_entity !== null
                    ? result.business_entity.name
                    : '-'
                "
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Alamat
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-textarea
                auto-grow
                :value="result.address"
                class="ma-2"
                style="margin:0; height:126px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-textarea>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Kota
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.city !== null ? result.city.name : ''"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Lat
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.location_lat"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Website
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.website"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>
          </div>

          <div style="width:50%;">
            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Code
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="rowData.code"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Email
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.email"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Skala Perusahaan
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-autocomplete
                :value="result.company_scale"
                :items="[
                  { id: 1, name: 'Kecil' },
                  { id: 2, name: 'Menengah' },
                  { id: 3, name: 'Besar' }
                ]"
                item-text="name"
                item-value="id"
                return-id
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                readonly
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Klasifikasi
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="
                  result.classification !== null
                    ? result.classification.name
                    : ''
                "
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Sub Klasifikasi
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="
                  result.sub_classification !== null
                    ? result.sub_classification.name
                    : ''
                "
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Provinsi
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.province !== null ? result.province.name : ''"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Kode Pos
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.zip_code"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Lng
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.location_long"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="padding:0;">
              <p class="ma-2" style="margin:0; font-weight:bold;">
                Tags
              </p>
            </v-col>
            <v-col cols="11" style="padding:0;">
              <v-text-field
                :value="result.tags"
                class="ma-2"
                style="margin:0;height:42px;"
                dense
                outlined
                truncate-length="10"
                readonly
              ></v-text-field>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
// import axios from 'axios'
// import buildType from '../../../services/buildType'
export default {
  components: {},
  props: ['result', 'rowData'],
  data() {
    return {
      dialog: false
    }
  },
  computed: {},
  created() {},
  methods: {
    close() {
      // this.letter = []
      this.dialog = false
    }
  }
}
</script>
